import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Icon, Colors } from '@class101/ui';
import { IconItemContainer } from '../docsComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icon"
    }}>{`Icon`}</h1>
    <p>{`Icons are created in SVG, and you can easily apply colors and effects through SVG or CSS properties.`}</p>
    <h2 {...{
      "id": "import"
    }}>{`Import`}</h2>
    <pre><code parentName="pre" {...{}}>{`import { Icon } from '@class101/ui';
`}</code></pre>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Icon} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <p>{`사용할 수 있는 아이콘은 제플린 파일을 참고하세요. 제플린의 `}<inlineCode parentName="p">{`ic-checkbox-on`}</inlineCode>{` 컴포넌트의 경우 `}<inlineCode parentName="p">{`<Icon.CheckboxOn />`}</inlineCode>{`와 대응합니다.`}</p>
    <Playground __position={1} __code={'<Icon.BellOutline />\n<Icon.Alert />\n<Icon.CheckboxOn />\n<Icon.Close />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Icon,
      Colors,
      IconItemContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Icon.BellOutline />
  <Icon.Alert />
  <Icon.CheckboxOn />
  <Icon.Close />
    </Playground>
    <h2 {...{
      "id": "size"
    }}>{`Size`}</h2>
    <p>{`Change the size of an icon with the size prop. Icon sizes uses the pixel scale.`}</p>
    <Playground __position={2} __code={'<Icon.BellOutline size={16} />\n<Icon.BellOutline size={24} />\n<Icon.BellOutline size={28} />\n<Icon.BellOutline size={36} />\n<Icon.BellOutline size={42} />\n<Icon.BellOutline size={48} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Icon,
      Colors,
      IconItemContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Icon.BellOutline size={16} />
  <Icon.BellOutline size={24} />
  <Icon.BellOutline size={28} />
  <Icon.BellOutline size={36} />
  <Icon.BellOutline size={42} />
  <Icon.BellOutline size={48} />
    </Playground>
    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p>{`You can set up to 2 colors for icons.
It is divided into basic fill color and accent color. The `}<inlineCode parentName="p">{`accentColor`}</inlineCode>{` is only a specific icon.
The props that set the basic fill color are `}<inlineCode parentName="p">{`fillColor`}</inlineCode>{`, which is present on all icons.`}</p>
    <h3 {...{
      "id": "fill-color"
    }}>{`Fill color`}</h3>
    <Playground __position={3} __code={'<Icon.BellOutline fillColor={Colors.gray700} />\n<Icon.BellOutline fillColor={Colors.red700} />\n<Icon.BellOutline fillColor={Colors.orange700} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Icon,
      Colors,
      IconItemContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Icon.BellOutline fillColor={Colors.gray700} />
  <Icon.BellOutline fillColor={Colors.red700} />
  <Icon.BellOutline fillColor={Colors.orange700} />
    </Playground>
    <h2 {...{
      "id": "icon-list"
    }}>{`Icon List`}</h2>
    <IconItemContainer mdxType="IconItemContainer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      